import React, { useEffect, useRef } from "react";

import { nextRender } from "@kikoff/client-utils/src/dom";
import useInitial from "@kikoff/hooks/src/useInitial";
import useUpdateEffect from "@kikoff/hooks/src/useUpdateEffect";
import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./Collapsible.module.scss";

interface CollapsibleProps {
  collapse: boolean;
  className?: string;
  innerClassName?: string;
  delay?: number;
}

const Collapsible: React.FC<CollapsibleProps> = ({
  collapse,
  className,
  innerClassName,
  children,
  delay = 300,
}) => {
  const initial = useInitial();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (collapse) ref.current.style.height = "0";
  }, []);

  useUpdateEffect(() => {
    const root = ref.current;

    root.style.height = `${(root.firstChild as HTMLDivElement).scrollHeight}px`;
    if (collapse) {
      nextRender(() => {
        root.style.height = "0";
      });
    } else {
      const timeout = setTimeout(() => {
        root.style.height = "";
      }, delay);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [collapse]);

  return (
    <div
      className={combineClasses(styles.collapsible, className)}
      style={{ transition: `height ${delay}ms` }}
      ref={ref}
    >
      <div
        className={combineClasses(styles.wrapper, innerClassName)}
        style={{
          height: initial && collapse && 0,
          opacity: collapse ? 0 : 1,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
