import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { web } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleProtoStatus } from "@kikoff/utils/src/proto";

import { RootState } from "@store";

import { createLoadableSelector, thunk } from "../utils";

const initialState = {
  gigsSubscriptionInfo: null as Mvno.GigsSubscriptionInfo,
};

export type MvnoState = typeof initialState;

const mvnoSlice = createSlice({
  name: "mvno",
  initialState,
  reducers: {
    setGigsSubscriptionInfo(
      state,
      { payload }: PayloadAction<MvnoState["gigsSubscriptionInfo"]>
    ) {
      state.gigsSubscriptionInfo = payload;
    },
  },
});

const { actions } = mvnoSlice;
export const {} = actions;
export default mvnoSlice.reducer;

export const selectMvnoStatus = createLoadableSelector(
  () => (state: RootState) => state.mvno.gigsSubscriptionInfo?.status,
  {
    loadAction: () => fetchGigsSubscriptionInfo(),
  }
);

export const fetchGigsSubscriptionInfo = () =>
  thunk((dispatch) =>
    webRPC.MVNO.getGigsSubscriptionInfo({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          const gigsSubscriptionInfo = data.gigsSubscriptionInfo;

          dispatch(actions.setGigsSubscriptionInfo(gigsSubscriptionInfo));

          return data.gigsSubscriptionInfo;
        },
        _DEFAULT() {
          throw new Error("Failed to load MVNO subscription information.");
        },
      })
    )
  );

export namespace Mvno {
  export type GigsSubscriptionInfo = web.public_.IGigsSubscriptionInfo;
}
