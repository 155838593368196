import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { kikoff } from "@kikoff/proto/src/protos";
import { webRPC } from "@kikoff/proto/src/rpc";
import { handleFailedStatus, handleProtoStatus } from "@kikoff/utils/src/proto";

import { RootState } from "@store";

import { createLoadableSelector, thunk } from "../utils";

const initialState = {
  enrollmentInfo: null as kikoff.api.hello_privacy.GetEnrollmentInfoResponse,
};

export type HelloPrivacyState = typeof initialState;

const helloPrivacySlice = createSlice({
  name: "helloPrivacy",
  initialState,
  reducers: {
    initHelloPrivacy(
      state,
      { payload }: PayloadAction<HelloPrivacyState["enrollmentInfo"]>
    ) {
      state.enrollmentInfo = payload;
    },
  },
});

export const fetchEnrollmentInfo = () =>
  thunk((dispatch) => {
    return webRPC.HelloPrivacyService.getEnrollmentInfo({}).then(
      handleProtoStatus({
        SUCCESS(data) {
          dispatch(actions.initHelloPrivacy(data));
          return data;
        },
        _DEFAULT: () => handleFailedStatus("Failed to get enrollment info"),
      })
    );
  });

export const selectHelloPrivacyEnrollment = createLoadableSelector(
  () => (state: RootState) => state.helloPrivacy.enrollmentInfo,
  {
    loadAction: fetchEnrollmentInfo,
  }
);

const { actions } = helloPrivacySlice;
export const { initHelloPrivacy } = actions;
export default helloPrivacySlice.reducer;
