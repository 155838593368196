import { trim } from "lodash-es";

import { web } from "@kikoff/proto/src/protos";

import format from "./format";

export { default as validate } from "./validate";
export { format };

interface joinTruthyOptions {
  join?: string;
  dedupe?: boolean;
  include?: any[];
  exclude?: any[];
}

export const joinTruthy = (
  array: Array<any>,
  separator = " ",
  { dedupe = false, include = [], exclude = [] }: joinTruthyOptions = {}
): string =>
  array
    .flat()
    .flatMap((str) =>
      str instanceof Object
        ? Object.entries(str).map(([key, value]) => !!value && key)
        : str
    )
    .filter(
      (str, i, arr) =>
        ((!exclude.includes(str) && str) || include.includes(str)) &&
        (dedupe ? arr.indexOf(str) === i : true)
    )
    .join(separator);

// const dedupePrefixes = (() => {
//   const prefix = new Set(["text:", "color:", ""]);

//   // return (str: string) =>
// })();

/**
 * Adds selector class(es) to initial class(es)
 *
 * @function
 * @param {String} initial Initial class(es) to be added to
 * @param {String|boolean} className Class to be added
 * @param {String} [condition] Condition to add class
 * @return {String} New set of classes
 */
export const combineClasses = (...classes: any[]) =>
  joinTruthy(classes, " ", { dedupe: true, include: [0] });

const SPACING_PREFIXES = ["m", "p"].flatMap((spacing) => {
  return ["t", "b", "l", "r", "x", "y"]
    .flatMap((direction) => [
      `${spacing}${direction}-`,
      spacing === "m" ? `-${spacing}${direction}-` : null,
    ])
    .filter(Boolean);
});
const CLS_PREFIXES = ["text:", "color:", ...SPACING_PREFIXES];

// Only includes last className that starts with prefixes defined below
export const cls = (...classes: string[]) => {
  const res = [];
  const encounteredPrefixes = new Set();
  for (const c of classes.flatMap((c) => (c ? c.split(" ") : [])).reverse()) {
    const prefix = CLS_PREFIXES.find((prefix) => c.startsWith(prefix));
    if (prefix) {
      if (encounteredPrefixes.has(prefix)) continue;
      encounteredPrefixes.add(prefix);
    }
    res.push(c);
  }
  return res.join(" ");
};

// We load zxcvbn dynamically since its frequency_lists file is almost 400kb gzipped
export const passwordScore = async (str: string): Promise<number> => {
  const { zxcvbn } = await import("zxcvbn-typescript");
  return zxcvbn(str.slice(0, 100)).score;
};

export function stringsFromKeys(typeDef, divider = ".") {
  const typeStrings = {};
  (function addTypes(currentTypes, res, parents = "") {
    for (const [key, value] of Object.entries(currentTypes)) {
      res[key] =
        { string: value, object: {} }[typeof value] ||
        (value === true && `${parents ? `${parents}${divider}` : ""}${key}`);
      value instanceof Object &&
        addTypes(
          value,
          res[key],
          `${parents ? `${parents}${divider}` : ""}${key}`
        );
    }
  })(typeDef, typeStrings);
  return typeStrings;
}

/**
 * For google maps places prediction matching assumes that matches don't overlap
 *
 * @returns Array of substrings where elements at odd indecies match
 */
export function getMatches(
  str: string,
  matches: google.maps.places.PredictionSubstring[]
): string[] {
  const res = [];
  let strPos = 0;
  for (const match of matches) {
    res.push(str.slice(strPos, match.offset));
    strPos = match.offset + match.length;
    res.push(str.slice(match.offset, strPos));
  }
  res.push(str.slice(strPos));
  return res;
}

export const getNumberPrefix = (str: string) => +str.match(/\d+/)[0];

export const routeFromNextQuery = (query: string | string[] | null) =>
  `/${
    query
      ? [query]
          .flat()
          .map((s) => trim(s, "/"))
          .join("/")
      : ""
  }`;

export const camelToKebab = (str) =>
  str?.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();

export const kebabToCamel = (str) =>
  str?.replace(/-./g, (match: string) => match[1].toUpperCase());

export const snakeToKebab = (str: string) => str?.replaceAll("_", "-");

export const kebabToSnake = (str: string) => str?.replaceAll("-", "_");

export const titleCase = (
  str: string,
  inputSeparator = " ",
  outputSeparator = " "
) =>
  str
    ?.toLowerCase()
    .split(inputSeparator)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(outputSeparator);

export const sentenceCase = (
  str: string,
  inputSeparator = " ",
  outputSeparator = " "
) => {
  if (!str) return;

  const [firstWord, ...words] = str.toLowerCase().split(inputSeparator);
  return [
    firstWord.charAt(0).toUpperCase() + firstWord.slice(1),
    ...words,
  ].join(outputSeparator);
};

// TODO: move to creditLine feature

export function clMinimum(
  creditLine: web.public_.ICreditLineAccount,
  onUpgrade = false
) {
  if (!creditLine) {
    return "$5";
  }
  const creditLineAgreement = onUpgrade
    ? creditLine.creditLineAgreementOnUpgrade
    : creditLine.creditLineAgreement;
  return creditLine
    ? format.money(creditLineAgreement.minimumPaymentAmountCents)
    : "$5";
}
clMinimum.withPercent = (creditLine: web.public_.ICreditLineAccount) =>
  creditLine
    ? `${format.money(
        creditLine.creditLineAgreement.minimumPaymentAmountCents
      )} or ${creditLine.creditLineAgreement.minimumPaymentAmountPercents}%`
    : "$5 or %5";

export const sliceIf = (
  str: string,
  method: "startsWith" | "endsWith",
  match: string
) =>
  str[method](match)
    ? {
        startsWith: str.slice(match.length),
        endsWith: str.slice(0, -match.length),
      }[method]
    : str;

/* eslint-disable */
export const hash = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};
/* eslint-enable */

export const conjunctionListArray = <Type>(
  items: Type[],
  separator: string,
  conjunction: string
): (Type | string)[] =>
  items
    .slice(0, -1)
    .flatMap((item, idx, items) =>
      idx < items.length - 1 ? [item, `${separator} `] : item
    )
    .concat(items.length > 1 ? [` ${conjunction} `, items.at(-1)] : items[0]);

export const conjunctionList = (items: string[], conjunction: string) =>
  conjunctionListArray(items, ",", conjunction).join("");
